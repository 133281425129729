@import "~antd/dist/antd.css";
/* Font Family */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(//db.onlinewebfonts.com/c/1c45e28f8e86cc89876f003b953cc3e9?family=SF+Pro+Text);
/* @import url(https://marketplace-fonts.s3.amazonaws.com/1c45e28f8e86cc89876f003b953cc3e9.woff2); */
/* @font-face {
  font-family: "SF Pro Text";
  src: local("SF Pro Text"),
    url("./assets/Fonts/SF-Pro-Text-Regular.otf") format("truetype");
} */
html {
    scroll-behavior: smooth;
}
:root {
    --swiper-theme-color: #fff !important;
}
body {
    background-color: #F3F4F6;
    font-family: "SF Pro Text" !important
}
a:hover {
    color: currentColor
}
p{
    font-weight: 500;
}

/* Swiper Custom CSS */
.swiper {
    z-index: 0 !important;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: auto !important;
    right: 50px !important;
}
.swiper-button-next, .swiper-button-prev {
    top: auto !important;
    bottom: 0 !important;
    margin-right: 16px;
    margin-bottom: 16px;
}
.swiper-button-prev, .swiper-button-next {
    border: 1px solid #fff;
    border-radius: 20px;
    height: 32px !important;
    width: 32px !important;
    background-color: #fff;
    color:#374151 !important;
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 12px !important;
    font-weight: 600 !important;
}
/* table tr th,
table tr td:first-child {
   border-top-left-radius: 8px !important;
   border-bottom-left-radius: 8px !important;
}

table tr th,
table tr td:last-child {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
} */

/* Scroll CSS */
::-webkit-scrollbar {
    background-color: #fff;
  border-radius: 4px;
  border: 0px solid#E5E7EB;
  width: 6px; 
  height: 8px;
}
::-webkit-scrollbar-thumb {
    background: #8080808c; 
    border-radius: 10px;
  }

.dropdown:hover > .dropdown-content {
	display: block;
    z-index: 100;
    top: 0;
}
/* Pagination */
.ant-pagination-jump-next .ant-pagination-item-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.ant-pagination-item-active a {
    color: #0D9488 !important;
}
.ant-pagination-item-active {
    border: 1px solid #0D9488 !important;
}
.anticon {
    display: block !important;
}
.ant-notification{
    right: 0px !important;
    top: 60px !important;
    bottom: auto !important;
}
.ant-select-selector{
    display: none !important;
}
.ant-pagination-options{
    display: none !important;
}
/* Ribbon */
.ribbon {
    width: 48%;
    height: 188px;
    float: right;
    margin-bottom: 30px;
    background: url(https://html5book.ru/wp-content/uploads/2015/10/snow-road.jpg);
    background-size: cover;
    color: white;
  }
  .ribbon3 {
    width: 200px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    right: -10px;
    z-index: 10;
    top: 20px;
    background: #F8463F;
    text-align: center;
  }
  .ribbon3:before, .ribbon3:after {
    content: "";
    position: absolute;
  }
  .ribbon3:before {
    height: 0;
    width: 0;
    top: -8.5px;
    right: 0.1px;
    border-bottom: 9px solid #F8463F;
    border-right: 9px solid transparent;
  }
  .ribbon3:after {
    height: 0;
    width: 0;
    left: -14.5px;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 15px solid #F8463F;
  }
  .ribbon2 {
    width: 60px;
    padding: 10px 0;
    position: absolute;
    top: -5px;
    right: 1.5rem;
    z-index: 10;
    text-align: center;
    border-top-left-radius: 3px;
    background: #0D9488;
    font-size: 14px;
   }
   .ribbon2:before {
    height: 0;
    width: 0;
    right: -5.5px;
    top: 0.1px;
    border-bottom: 6px solid #0D9488;
    border-right: 6px solid transparent;
   }
   .ribbon2:before, .ribbon2:after {
     content: "";
     position: absolute;
   }
   .ribbon2:after {
     height: 0;
     width: 0;
     bottom: -29.5px;
     left: 0;
     border-left: 30px solid #0D9488;
     border-right: 30px solid #0D9488;
     border-bottom: 30px solid transparent;
   }
   .promotion {
    border: 1px #0D9488 solid;
    animation: blink 2s;
    animation-iteration-count: infinite;
   }
   .maintenance {
    border: 1px #F8463F solid;
    animation: blink 2s;
    animation-iteration-count: infinite;
   }
   @keyframes blink { 50% { border-color: #fff; }  }