.navigation-container {
  width: 100px;
  box-shadow: 2px 2px 2px rgb(218, 218, 218);
  position: fixed;
  left: 0;
  top: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 0 5px 5px 0;
}

.nav-items {
  text-align: center;
  /* padding: 10px 0; */
  border-bottom: 1px solid #cfcfcf71;
  width: 90%;
  margin: 35px auto 0 auto;
}
